import React, { Dispatch, SetStateAction, RefObject } from "react"
import { FieldErrors, UseFormWatch } from "react-hook-form"
import i18next from "i18next"
import api from "../../api/api"
import store from "../../store"
import { apiURL, dashboardURL, isDevEnv } from "../../app.config"
import {
  CustomErrorMessageType,
  DocumentType,
  MainCountriesTypes,
  RegisterErrorsType,
  RegisterFormData
} from "./RegisterForm.types"
import {
  birthDateRegExp,
  differentFormatBirthDateRegExp,
  noDocumentAllowedAgeRange,
  noDocumentAllowedCountry,
} from "../../constants/constants"
import dayjs from "dayjs"

type documentTypes = {
  type: string;
  label: string;
}

const ESLabels = { // no translations needed
  DNI: "DNI",
  NIE: "NIE"
}

export const handleSetNoDocumentGivenAllowed = (
    birthDate: string,
    nationality: string,
    setNoDocumentGivenAllowed: Dispatch<SetStateAction<boolean>>,
    isDifferentDateFormat?: boolean
) => {
  if (birthDate?.match(isDifferentDateFormat ? differentFormatBirthDateRegExp : birthDateRegExp)) {
    setNoDocumentGivenAllowed(
      dayjs().diff(dayjs(birthDate, isDifferentDateFormat ? "DD-MM-YYYY" : "YYYY-MM-DD"), "years") < noDocumentAllowedAgeRange &&
      nationality === noDocumentAllowedCountry.toUpperCase()
    )
  }
}

export const getDocumentTypesForCountry = (nationality: string): Array<documentTypes> => {
  switch (nationality) {
    case MainCountriesTypes.PL:
      return [
        {
          type: DocumentType.PESEL,
          label: i18next.t("defaultTranslations:registerForm:document:pesel")
        },
      ]
    case MainCountriesTypes.ES:
      return [
        {
          type: DocumentType.PESEL,
          label: i18next.t("defaultTranslations:registerForm:document:pesel")
        },
        {
          type: DocumentType.IDENTITY_CARD_NUMBER,
          label: ESLabels.DNI,
        },
        {
          type: DocumentType.IDENTITY_CARD_NUMBER,
          label: ESLabels.NIE
        },
        {
          type: DocumentType.PASSPORT,
          label: i18next.t("defaultTranslations:registerForm:document:passport")
        },
      ]
    default:
      return [
        {
          type: DocumentType.PESEL,
          label: i18next.t("defaultTranslations:registerForm:document:pesel")
        },
        {
          type: DocumentType.IDENTITY_CARD_NUMBER,
          label: i18next.t("defaultTranslations:registerForm:document:nationalId")
        },
        {
          type: DocumentType.PASSPORT,
          label: i18next.t("defaultTranslations:registerForm:document:passport")
        },
      ]
  }
}

export const scrollIntoError = (errorList: string[], errorRef: RefObject<HTMLInputElement | HTMLDivElement>) => {
  if (errorList) {
    errorRef?.current?.scrollIntoView
    (
      {
        behavior: "smooth",
        block: "center"
      }
    )
  }
}

export const detectPatientCountry = (setDetectedCountry: Dispatch<SetStateAction<string>>, initCountriesList: () => Promise<void>) => {
  api.request({
    method: "GET",
    url: "https://ipinfo.io",
  }).then((data) => {
    setDetectedCountry(data?.data?.country)
  })

  initCountriesList()
}

export const watchBirthDate = (
    watch: UseFormWatch<RegisterFormData>,
    setNoDocumentGivenAllowed: Dispatch<SetStateAction<boolean>>,
    isDifferentDateFormat?: boolean
) => {
  const birthDateField = "birthDate"

  watch((value) => {
    const birthDateValue = value?.[birthDateField]
    const nationality = (document.getElementsByName("nationality")?.[0] as HTMLSelectElement)?.value

    if (birthDateValue?.match(isDifferentDateFormat ? differentFormatBirthDateRegExp : birthDateRegExp)) {
      setNoDocumentGivenAllowed(
        dayjs().diff(dayjs(birthDateValue, isDifferentDateFormat ? "DD-MM-YYYY" : "YYYY-MM-DD"), "years") < noDocumentAllowedAgeRange &&
        nationality === noDocumentAllowedCountry.toUpperCase()
      )
    }
  })
}

export const checkScrollToError = (errors: FieldErrors<RegisterFormData>) => {
  const elements = Object.keys(errors).map(name => document.getElementsByName(name)[0]).filter(el => !!el)

  elements.sort((a, b) => b.scrollHeight + a.scrollHeight)
  elements[0]?.scrollIntoView({behavior: "smooth", block: "center"})
}

export const handleChangeDocumentType = (
  event: React.ChangeEvent<HTMLSelectElement>|string,
  setIdNumberFieldDisabled: Dispatch<SetStateAction<boolean>>,
  setDocumentValueVisibility: Dispatch<SetStateAction<boolean>>,
  setDocumentFieldName: Dispatch<SetStateAction<string>>
) => {
  const selectedDocumentType = typeof event === "string"
      ? event
      : event?.target?.value

  setIdNumberFieldDisabled(!selectedDocumentType)

  if (selectedDocumentType === DocumentType.NO_DOCUMENT) {
    setDocumentValueVisibility(false)
    setDocumentFieldName("")
    return
  }

  setDocumentValueVisibility(true)

  switch (selectedDocumentType) {
    case DocumentType.PESEL:
      return setDocumentFieldName("pesel")
    case DocumentType.PASSPORT:
      return setDocumentFieldName("identityNumber")
    case DocumentType.IDENTITY_CARD_NUMBER:
      return setDocumentFieldName("identityNumber")
  }
}

export const handleSetPrefixAndRenderDocumentsList = (setPrefixCodeFlagSrc: Dispatch<SetStateAction<string>>, renderDocumentTypeOptions: (countryCode: string) => void) => {
  const countryCode = (document.getElementsByName("nationality")?.[0] as HTMLSelectElement)?.value

  if (!store.getState().userSettings.prefixCodeSet) {
    setPrefixCodeFlagSrc(`/flags-register-form/${countryCode.toLowerCase()}.png`)
  }

  renderDocumentTypeOptions(countryCode)
}

export const getApiUrl = () => {
  const storeState = store.getState()

  return isDevEnv
    ?  "https://localhost:3000"
    : (storeState.clinic.clinicSettings.domain ? `//${storeState.clinic.clinicSettings.domain}` : apiURL)
}

export const insuranceVerification = (errorType: number, setCustomErrorMessage: React.Dispatch<React.SetStateAction<CustomErrorMessageType>>) => {
  const storeState = store.getState()
  const title = i18next.t("defaultTranslations:registerForm:customMessage:cannotCreateAccount")

  if (storeState.clinic.clinicSettings?.clinicSignalIdunaSettings?.insuranceVerificationEnabled) {
    switch (errorType) {
      case RegisterErrorsType.SIGNAL_IDUNA_INSURANCE_NOT_ACTIVE:
        return setCustomErrorMessage({
          title,
          message: i18next.t("defaultTranslations:registerForm:customMessage:noInsuranceError")
        })
      case RegisterErrorsType.SIGNAL_IDUNA_INSURANCE_ERROR:
        return setCustomErrorMessage({
          title,
          message: i18next.t("defaultTranslations:registerForm:customMessage:policyError")
        })
      default:
        setCustomErrorMessage({
          title,
          message: i18next.t("defaultTranslations:registerForm:customMessage:generalError")
        })
    }
  }
}

export const getDashboardUrl = () => `https://${store.getState().clinic.clinicSettings.frontendSettings.dashboardUrl}` || dashboardURL
